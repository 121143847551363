import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "../../../../assets/css/accordianCustom.css";
import { IMAGES } from "../../../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import AreYouSure from "../../../modals/areYouSure";
import { useSidebarContext } from "../../../../context/sidebarContext";
import { GET_MATCHED_INTEREST_LIST_HISTORY, GET_MATCHED_INTEREST_STATUS, MAKE_USER_ONLINE, SET_YOUR_PROFILE } from "../../../../config";
import { handleRequest } from "../../../../services";
import MatchedInterestModal from "../../../modals/MatchedInterestModal";



const Sidebar = (props) => {
  const pathArrary = window.location.pathname.split("/")
  const [interestArray, setInterestArray] = useState([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const { isSidebarVisible, setIsSidebarVisible, userDetail, callStartTime, setVideoButtonOpen } = useSidebarContext();
  const [isInterstingMatchingOn, setIsInterstingMatchingOn] = useState(userDetail.interest_matching === 0 ? false : true);

  const editDetails = JSON.parse(localStorage.getItem("userData"))
  const navigate = useNavigate();
  const {
    path,
    sidebarOpen,
    setSideBarOpen,
    myAccountModal,
    setMyAccountModal,
    fetchDetails
  } = props;

  const handleMyAccountModal = () => {
    setMyAccountModal(true);
  };

  const getInterestList = async () => {
    try {
      const response = await handleRequest("GET", GET_MATCHED_INTEREST_LIST_HISTORY);
      setInterestArray(response.body)
      console.log(response.body, "bodyyyy")

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInterestList()
  }, [])

  // const handleUpdate = async () => {
  //   setIsInterstingMatchingOn(!isInterstingMatchingOn);
  //   const data = {
  //     interest_matching: !isInterstingMatchingOn ? 1 : 0,
  //     username: editDetails?.userData?.username,
  //     avatar: editDetails?.userData?.avatar,
  //     creativity_interest: editDetails?.userData?.creativity_interest,
  //     sports_interest: editDetails?.userData?.sports_interest,
  //     going_out_interest: editDetails?.userData?.going_out_interest,
  //     film_tv_interest: editDetails?.userData?.film_tv_interest,
  //     own_interest: editDetails?.userData?.own_interest
  //   }
  //   try {
  //     const response = await handleRequest("POST", SET_YOUR_PROFILE, data)
  //     if (response.code == 200) {
  //       localStorage.setItem("token", JSON.stringify(response?.body?.token))
  //       fetchDetails();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleUpdate = async () => {
    setIsInterstingMatchingOn(!isInterstingMatchingOn);
    try {
      const response = await handleRequest("GET", GET_MATCHED_INTEREST_STATUS);
      setInterestArray(response.body)
      fetchDetails();
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    // getMakeUserOnline()
    navigate(ROUTES.SIGNIN);
  };

  // const getMakeUserOnline = async () => {
  //   try {
  //     const response = await handleRequest("GET", MAKE_USER_ONLINE);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleSidebarClose = () => {
    setIsSidebarVisible(false)
    setVideoButtonOpen(false)
  }

  const openLink = (url) => {
    window.open(url, '_blank');
    setIsSettingOpen(true)
  };


  return (
    <>
      <div style={{ width: !isSidebarVisible && "0%" }}
        className={`${sidebarOpen ? "sidebar-wrapper" : " sidebar-wrapper sidebar-closed"
          }  ${isSidebarVisible ? "z-index1" : "z-index-1"}`}
      >
        <div style={{ display: !isSidebarVisible && "none" }} className="logo-sidebar">
          <img src={IMAGES.DASHLOGO} className="img-fluid" />
        </div>
        <div onClick={handleSidebarClose} style={{ cursor: "pointer" }} className="toggleclose-icon">
          <img src={IMAGES.CLOSSBUTTON} className="img-fluid" />
        </div>
        <div className="">
          <ul style={{ display: !isSidebarVisible && "none" }} className="ps-0 menu-listed custom-menulisted">
            {/* <li className={"margin-accordion"}> */}
            <Accordion allowZeroExpanded="true">
              <AccordionItem onClick={() => navigate(ROUTES.HOME)}>
                <AccordionItemHeading
                  className={`${path === ROUTES.HOME && !myAccountModal && !isDeleteModalOpen  && "active"
                    } menu-listing`}
                >
                  <AccordionItemButton>
                    <a className="side">
                      {" "}
                      <img
                        src={IMAGES.VIDEOICON}
                        className="pe-3 white-icon side-icon"
                      ></img>{" "}
                      <img src={IMAGES.WHITEVIDEOICON} className="pe-3 grad-icon" />
                      <span>Video Chat</span>
                    </a>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading
                  className={`   ${(path === ROUTES.FRIENDS ||
                    path === ROUTES.MESSAGES ||
                    path === ROUTES.VIDEO_CHAT_HISTORY) &&
                    !myAccountModal && !isDeleteModalOpen  &&
                    "active"
                    } menu-listing`}
                >
                  <AccordionItemButton>
                    <a>
                      {" "}
                      <img
                        src={IMAGES.WHITEMYASOCIALICON}
                        className="pe-3 white-icon grad-white-icon"
                      ></img>{" "}
                      <img src={IMAGES.MYASOCIALICON} className="pe-3 grad-icon grad-white-icon" />
                      <span> My Asocial</span>
                    </a>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  onClick={() => navigate(ROUTES.FRIENDS)}
                  style={{ cursor: "pointer" }}
                  className={`${path === ROUTES.FRIENDS && "accordion-active"
                    } accordion__panel`}
                >
                  Friends
                </AccordionItemPanel>
                <AccordionItemPanel
                  onClick={() => {
                    navigate(ROUTES.MESSAGES)
                    localStorage.setItem("messageNavigate", 0)
                  }
                  }
                  style={{ cursor: "pointer" }}
                  className={`${path === ROUTES.MESSAGES && "accordion-active"
                    } accordion__panel`}
                >
                  Messages
                </AccordionItemPanel>
                <AccordionItemPanel
                  onClick={() => navigate(ROUTES.VIDEO_CHAT_HISTORY)}
                  style={{ cursor: "pointer" }}
                  className={`${path === ROUTES.VIDEO_CHAT_HISTORY && "accordion-active"
                    } accordion__panel`}
                >
                  Chat History
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading
                  className={`menu-listing menu-listing-last ${((myAccountModal || isDeleteModalOpen) ) && "active"}`}
                >
                  <AccordionItemButton>
                    <a>
                      <img src={IMAGES.USERICON} className="white-icon side-icon-end" style={{paddingRight:'1.2rem'}}></img>
                      <img src={IMAGES.USERTHEME} className="grad-icon" style={{paddingRight:'1.2rem'}}></img>
                      <span> My Account</span>
                    </a>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  onClick={handleMyAccountModal}
                  style={{ cursor: "pointer" }}
                  className={`${myAccountModal && "accordion-active"
                    } accordion__panel`}
                >
                  Profile
                </AccordionItemPanel>
                <AccordionItemPanel style={{ cursor: "pointer" }}
                  onClick={() => setIsDeleteModalOpen(true)}
                  className={`${isDeleteModalOpen && "accordion-active"
                    } accordion__panel`}
                >
                  <div className="d-flex justify-content-between">
                    <span>Delete Account</span>{" "}

                  </div>
                </AccordionItemPanel>

              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading className={`menu-listing menu-listing-last`}
                >
                  <AccordionItemButton>
                    <a>
                      <img
                        src={IMAGES.SETTINGICON}
                        className="pe-3 white-icon side-icon-end"
                      ></img>
                      <img
                        src={IMAGES.SETTINGTHEME}
                        className="pe-3 grad-icon"
                      ></img>
                      <span>Settings</span>
                    </a>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-active accordion__panel" onClick={() => openLink("https://asocial.chat/privacy-policy/")}
                  style={{ cursor: 'pointer' }} >
                  Privacy Policy
                </AccordionItemPanel>
                <AccordionItemPanel onClick={() => openLink('https://asocial.chat/terms-of-service/')}
                  style={{ cursor: 'pointer' }}>Terms of Service</AccordionItemPanel>
                <AccordionItemPanel>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Interest Matching</span>{" "}
                    <div className="on-off-toggle side-on-toggle">
                      <input
                        className="on-off-toggle__input"
                        type="checkbox"
                        id="bopis"
                        checked={isInterstingMatchingOn}
                        onChange={handleUpdate}
                      />
                      <label for="bopis" className="on-off-toggle__slider side-on-toggle"></label>
                    </div>
                  </div>
                </AccordionItemPanel>

                <AccordionItemPanel style={{ cursor: "pointer" }}
                  onClick={() => setIsInterestModalOpen(true)}
                >
                  <div className="d-flex justify-content-between">
                    <span>Show Matched Interests</span>{" "}
                  </div>
                </AccordionItemPanel>

              </AccordionItem>
            </Accordion>
          </ul>
          <div style={{ display: !isSidebarVisible && "none" }} className="logout_wrapper">
            <button
              type="button"
              onClick={handleLogout}
              className="logout-btn text-white f18-size fw-500 text-decoration-none"
            >
              <img src={IMAGES.LOGOUTICON} className="pe-3"></img>{" "}
              <span>Logout</span>
            </button>
          </div>
        </div>
        {
          isDeleteModalOpen && <AreYouSure
            setIsDeleteModalOpen={setIsDeleteModalOpen}
          />
        }

        {
          isInterestModalOpen && <MatchedInterestModal interestArray={interestArray} setIsInterestModalOpen={setIsInterestModalOpen} check="show" />
        }
      </div>
    </>
  );
};

export default Sidebar;
